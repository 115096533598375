import React from 'react';
import { useGetGroupName } from '../hooks/useGroupName';
import { FaCar, FaArrowDownShortWide } from "react-icons/fa6";
import { FaHome } from "react-icons/fa";

interface Content {
  title: string;
  link: string;
  icon: React.ReactNode;
  openInNewTab?: boolean;
}

const neputaContents: Content[] = [
  {
    title: '運行順',
    link: 'https://hirosaki-neputa.com/order-of-operation/',
    icon: <FaArrowDownShortWide />,
    openInNewTab: true,
  },
  {
    title: '交通規制',
    link: 'https://www.hirosaki-kanko.or.jp/edit.html?id=cat02_summer_neputa#kotsukisei',
    icon: <FaCar />,
    openInNewTab: true,
  },
  {
    title: 'トップへ戻る',
    link: '/hirosaki',
    icon: <FaHome />,
    openInNewTab: false,
  },
];

const SidebarMenuContent: React.FC = () => {
  const groupName = useGetGroupName();

  let contents: Content[] = [];
  let title = '';

  switch (groupName) {
    case 'neputa':
      title = 'ねぷたまつり情報';
      contents = neputaContents;
      break;
    default:
      return null;
  }

  return (
    <div className="py-4">
      <p className="mt-4 mb-2 text-lg">{title}</p>
      <ul>
        {contents.map((content, index) => (
          <li key={index}>
            <a
              href={content.link}
              target={content.openInNewTab ? "_blank" : "_self"}
              rel={content.openInNewTab ? "noopener noreferrer" : undefined}
              className="flex items-center px-4 p-2 rounded-lg hover:bg-gray-300 transition-colors duration-200"
            >
              <div className='mr-3 text-lg'>
                {content.icon}
              </div>
              <p className="font-light text-sm">{content.title}</p>
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default SidebarMenuContent;
