import React from 'react';
import { useGetGroupName } from '../hooks/useGroupName';

const SidebarMenuTitle: React.FC = () => {
    const groupName = useGetGroupName();

    switch (groupName) {
        case 'neputa':
            return (
                <div className="flex flex-col justify-between py-4">
                    <h2 className="text-2xl font-semibold tracking-wide text-center">
                        弘前ねぷたまつり
                        <br />
                        位置情報発信
                    </h2>
                    <div className="w-full h-1 bg-black rounded-full mt-4"></div>
                </div>
            );
        default:
            return null;
    }
}

export default SidebarMenuTitle;
