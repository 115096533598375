import React from 'react';
import { useBusLocations } from '../hooks/useBusLocation';
import { useUpdateGroupName } from '../hooks/useGroupName';
import { BusGroup } from '../api/getBusLocationType';
import LeafletMap from './LeafletMap';
import QuestionnaireForm from './QuestionnaireForm';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const BusGroupPage: React.FC = () => {
  const busLocations: BusGroup | null = useBusLocations('neputa');

  // groupNameをstoreに保存
  useUpdateGroupName('neputa');

  return (
    <div>
      {/* <QuestionnaireForm/> */}
      <LeafletMap busLocations={busLocations}/>
      {/* <ToastContainer /> */}
    </div>
  );
}

export default BusGroupPage;