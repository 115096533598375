import React from 'react';
import { useNavigate } from 'react-router-dom';

const SidebarMenuQuestionnaireButton: React.FC = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate('/hirosaki?openModal=true');
  };

  return (
    <button
      onClick={handleClick}
      className="w-full flex items-center justify-center px-2 py-2 rounded-lg bg-gray-500 text-white font-semibold shadow-md hover:bg-gray-700 transition-colors duration-200"
    >
      <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-3 7h3m-3 4h3m-6-4h.01M9 16h.01" />
      </svg>
      アンケートに回答する
    </button>
  );
};

export default SidebarMenuQuestionnaireButton;
