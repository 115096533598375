import React from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

interface ContactFormModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: () => void;
}

interface FormData {
  hometown: string;
  source: string;
  message: string;
}

const FormKeys = {
  formUrl:
    'https://docs.google.com/forms/u/0/d/e/1FAIpQLSdpkVQiTvIx0fAKicC1d9IOK0HUYmGJche6EwczPaODvRiaOQ/formResponse',
  hometown: 'entry.1397590281',
  source: 'entry.1543352501',
  message: 'entry.1655456715',
};

const ContactFormModal: React.FC<ContactFormModalProps> = ({ isOpen, onClose, onSubmit }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>();

  const handleFormSubmit = async (data: FormData) => {
    const body = new URLSearchParams({
      [FormKeys.hometown]: data.hometown,
      [FormKeys.source]: data.source,
      [FormKeys.message]: data.message,
    }).toString();

    try {
      const response = await fetch(FormKeys.formUrl, {
        method: 'POST',
        mode: 'no-cors',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: body,
      });

      if (response.type === 'opaque' || response.ok) {
        onSubmit();
      } else {
        toast.error('送信に失敗しました。');
      }
    } catch (error) {
      console.error('エラーです', error);
      toast.error('送信に失敗しました。');
    }
  };

  if (!isOpen) return null;
  return (
    <div className="fixed inset-0 flex items-center justify-center p-4 sm:p-0">
      <div className="relative bg-white p-6 rounded-lg shadow-lg w-full max-w-md sm:max-w-xl overflow-y-auto">
        <button
          type="button"
          className="absolute top-2 right-2 text-gray-500 hover:text-gray-700 focus:outline-none"
          onClick={onClose}
        >
          <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path>
          </svg>
        </button>
        <h2 className="text-xl font-bold mb-2">アンケート</h2>
        <p className="text-gray-700 text-sm sm:text-md">アンケートにご協力いただき、ありがとうございます。</p>
        <p className="text-gray-700 mb-4 text-sm sm:text-md">このフォームは、弘前大学 丹波研究室に送信されます。</p>
        <form onSubmit={handleSubmit(handleFormSubmit)}>
          <div className="mb-4">
            <div className='flex items-center'>
              <label className="block text-gray-700 mb-2 mr-4"><span className="text-red-500 pr-2">*</span>どこから来ましたか？:</label>
              {errors.hometown && <span className="text-red-500 text-sm mb-2">選択してください</span>}
            </div>
            <div className="grid grid-cols-2 sm:grid-cols-1 gap-2">
              <label className="flex items-center">
                <input type="radio" value="弘前市" className="mr-2" {...register('hometown', { required: true })}/>
                弘前市
              </label>
              <label className="flex items-center">
                <input type="radio" value="青森県" className="mr-2" {...register('hometown', { required: true })}/>
                青森県
              </label>
              <label className="flex items-center">
                <input type="radio" value="東北" className="mr-2" {...register('hometown', { required: true })}/>
                東北
              </label>
              <label className="flex items-center">
                <input type="radio" value="その他" className="mr-2" {...register('hometown', { required: true })}/>
                その他
              </label>
            </div>
          </div>
          <div className="mb-4">
            <div className='flex items-center'>
              <label className="block text-gray-700 mb-2 mr-4"><span className="text-red-500 pr-2">*</span>このサイトを知ったきっかけ:</label>
              {errors.source && <span className="text-red-500 text-sm mb-2">選択してください</span>}
            </div>
            <div className="grid grid-cols-2 sm:grid-cols-1 gap-2">
              <label className="flex items-center">
                <input type="radio" value="うちわ" className="mr-2"{...register('source', { required: true })} />
                うちわ
              </label>
              <label className="flex items-center">
                <input type="radio" value="web広告" className="mr-2" {...register('source', { required: true })}/>
                web広報
              </label>
              <label className="flex items-center">
                <input type="radio" value="知人" className="mr-2" {...register('source', { required: true })}/>
                知人
              </label>
              <label className="flex items-center">
                <input type="radio" value="その他" className="mr-2" {...register('source', { required: true })}/>
                その他
              </label>
            </div>
          </div>
          <div className="mb-4">
            <div>
              <label className="block text-gray-700 mb-2">ご意見・ご要望（感想）:</label>
            </div>
            <textarea
              className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:border-blue-500"
              rows={4}
              placeholder="感想をお聞かせください"
              {...register('message')}
            ></textarea>
          </div>
          <div className="flex justify-end">
            <button
              type="submit"
              className="w-full px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-700 focus:outline-none"
            >
              送信
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};export default ContactFormModal;