import React, { useEffect, useState } from 'react';
import { Marker } from 'react-leaflet';
import L from 'leaflet';
import { BusGroup, Bus } from '../api/getBusLocationType';
import neputaIcon from '../assets/images/neputaIcon.webp';
import BusMarkerDetails from './busMarkersDetails';

interface BusMarkersProps {
  busLocations: BusGroup | null;
}

const BusMarkers: React.FC<BusMarkersProps> = ({ busLocations }) => {
  const [details, setDetails] = useState<{ [key: string]: any }>({});
  const [selectedBus, setSelectedBus] = useState<Bus | null>(null);

  useEffect(() => {
    if (busLocations) {
      busLocations.forEach((bus) => {
        fetch(`${process.env.PUBLIC_URL}/details/${bus.name}.json`)
          .then(response => response.json())
          .then(data => {
            setDetails(prevDetails => ({
              ...prevDetails,
              [bus.name]: data,
            }));
          });
      });
    }
  }, [busLocations]);

  const createCustomIcon = (busName: string) => {
    const detail = details[busName];
    const detailName = detail ? detail.name : '';
    const displayName = detailName.slice(0, 5); // 最初の5文字を取得

    return L.divIcon({
      html: `
        <div style="text-align: center; display: inline-block;">
          <img src="${neputaIcon}" style="width: 45px; height: 45px;" />
          <div style="width: 60px; color: black; font-weight: bold; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">${displayName}</div>
        </div>
      `,
      className: '', // デフォルトのclassNameをクリア
    });
  };

  const handleMarkerClick = (bus: Bus) => {
    setSelectedBus(bus);
  };

  const handleCloseClick = () => {
    setSelectedBus(null);
  };

  const formatTimeDifference = (acceptedAt: string) => {
    const updatedTime = new Date(acceptedAt).getTime();
    const currentTime = new Date().getTime();
    const timeDifference = currentTime - updatedTime;

    const seconds = Math.floor(timeDifference / 1000);

    return `${seconds}秒前`;
  };

  return (
    <>
      {busLocations?.map((bus, index) => (
        <Marker
          key={index}
          position={[bus.latitude, bus.longitude]}
          icon={createCustomIcon(bus.name)}
          eventHandlers={{
            click: () => handleMarkerClick(bus),
          }}
        />
      ))}
      {selectedBus && (
        <BusMarkerDetails
          selectedBus={selectedBus}
          onClose={handleCloseClick}
          formatTimeDifference={formatTimeDifference}
        />
      )}
    </>
  );
};

export default BusMarkers;
