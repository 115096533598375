import React, { useEffect, useState } from 'react';
import { MapContainer, TileLayer, useMap, Polyline } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';

import { BusGroup } from '../api/getBusLocationType';
import BusMarkers from './busMarkers';
import UserLocationMarker from './userLocationMarker';
import { useGetGroupName } from '../hooks/useGroupName';
import { getMapConfig, MapConfig } from '../utils/mapConfig'; 
import HamburgerMenu from './hamburgerMenu';
import SidebarMenu from './sidebarMenu';
import { useMenuState } from '../hooks/useMenueState';
import Pulldown from './pulldown';

interface StaticMapContainerProps {
  busLocations: BusGroup | null;
}

const MapUpdater: React.FC<MapConfig> = ({ center, zoom }) => {
  const map = useMap();
  useEffect(() => {
    map.setView(center, zoom);
  }, [center, zoom, map]);
  return null;
};

const LeafletMap: React.FC<StaticMapContainerProps> = React.memo(({ busLocations }) => {
    const groupName = useGetGroupName();
    const [mapState, setMapState] = useState<MapConfig>({ center: [40.60754, 140.46566], zoom: 12, minZoom: 11, lines: undefined });
    const { isSidebarOpen, toggleMenu } = useMenuState();

    useEffect(() => {
        const config = getMapConfig(groupName);
        if (config && config.lines) {
            setMapState({ center: config.center, zoom: config.zoom, minZoom: config.minZoom, lines: config.lines });
        }
    }, [groupName]);

  return (
    <MapContainer style={{ height: "100vh", width: "100vw" }} center={mapState.center} zoom={mapState.zoom} minZoom={mapState.minZoom} maxZoom={18}>
      <MapUpdater {...mapState} />
      <TileLayer
        url="https://mt1.google.com/vt/lyrs=m&x={x}&y={y}&z={z}"
        attribution='&copy; <a href="https://www.google.com/maps">Google Maps</a> contributors'
      />
      <div className="absolute top-2 z-1000 flex justify-center items-center w-full">
        <Pulldown />
      </div>
      <HamburgerMenu isOpen={isSidebarOpen} toggleMenu={toggleMenu} />
      <SidebarMenu isOpen={isSidebarOpen} />
      <BusMarkers busLocations={busLocations} />
      <UserLocationMarker />
      {mapState.lines && mapState.lines.map((line, index) => (
        <Polyline key={index} positions={line.coordinates} color={line.color} />
      ))}
    </MapContainer>
  );
});

export default LeafletMap;
