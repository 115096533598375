import React, { useEffect, useState } from 'react';
import { Rnd } from 'react-rnd';
import { Bus } from '../api/getBusLocationType';
import { AiOutlineClose } from 'react-icons/ai';

interface BusMarkerDetailsProps {
  selectedBus: Bus | null;
  onClose: () => void;
  formatTimeDifference: (acceptedAt: string) => string;
}

const BusMarkerDetails: React.FC<BusMarkerDetailsProps> = ({ selectedBus, onClose, formatTimeDifference }) => {
  const [detail, setDetail] = useState<any>(null);
  const [isMobile, setIsMobile] = useState<boolean>(false);

  useEffect(() => {
    if (selectedBus) {
      fetch(`${process.env.PUBLIC_URL}/details/${selectedBus.name}.json`)
        .then(response => response.json())
        .then(data => {
          setDetail(data);
        });
    }
  }, [selectedBus]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    handleResize(); // 初期値を設定
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const preventBackgroundScroll = () => {
      document.body.style.overflow = 'hidden';
      document.body.style.position = 'fixed';
      document.body.style.width = '100%';
    };
  
    const allowBackgroundScroll = () => {
      document.body.style.overflow = '';
      document.body.style.position = '';
      document.body.style.width = '';
    };
  
    if (selectedBus) {
      preventBackgroundScroll();
    } else {
      allowBackgroundScroll();
    }
  
    return () => {
      allowBackgroundScroll();
    };
  }, [selectedBus]);
  

  if (!selectedBus || !detail) return null;

  const handleTouchCloseClick = (e: React.TouchEvent) => {
    e.stopPropagation();
    onClose();
  };

  const renderDetails = () => (
    <>
      {/* <div className="p-2 border-b border-gray-300">
        <div className="grid grid-cols-2">
          <p className="text-base">子機ID:</p>
          <p className="text-base">{selectedBus.name}</p>
        </div>
      </div> */}
      {/* <div className="p-2 border-b border-gray-300">
        <div className="grid grid-cols-2">
          <p className="text-base">緯度:</p>
          <p className="text-base">{selectedBus.latitude}</p>
        </div>
      </div> */}
      {/* <div className="p-2 border-b border-gray-300">
        <div className="grid grid-cols-2">
          <p className="text-base">経度:</p>
          <p className="text-base">{selectedBus.longitude}</p>
        </div>
      </div> */}
      <div className="p-2 border-y border-gray-300">
        <div className="grid grid-cols-2">
          <p className="text-base">更新時刻:</p>
          <p className="text-base">{formatTimeDifference(selectedBus.acceptedAt)}</p>
        </div>
      </div>
      {detail.size && (
        <div className="p-2 border-b border-gray-300">
          <div className="grid grid-cols-2">
            <p className="text-base">サイズ:</p>
            <p className="text-base">{detail.size}</p>
          </div>
        </div>
      )}
      {detail.type && (
        <div className="p-2 border-b border-gray-300">
          <div className="grid grid-cols-2">
            <p className="text-base">型:</p>
            <p className="text-base">{detail.type}</p>
          </div>
        </div>
      )}
      {detail.height && (
        <div className="p-2 border-b border-gray-300">
          <div className="grid grid-cols-2">
            <p className="text-base">高さ:</p>
            <p className="text-base">{detail.height}m</p>
          </div>
        </div>
      )}
      {detail.painter && (
        <div className="p-2 border-b border-gray-300">
          <div className="grid grid-cols-2">
            <p className="text-base">絵師:</p>
            <p className="text-base">{detail.painter}</p>
          </div>
        </div>
      )}
      {detail.frameworker && (
        <div className="p-2 border-b border-gray-300">
          <div className="grid grid-cols-2">
            <p className="text-base">骨組み:</p>
            <p className="text-base">{detail.frameworker}</p>
          </div>
        </div>
      )}
      {detail.mirrorPainting && (
        <div className="p-2 border-b border-gray-300">
          <div className="grid grid-cols-2">
            <p className="text-base">鏡絵:</p>
            <p className="text-base">{detail.mirrorPainting}</p>
          </div>
        </div>
      )}
      {detail.farewellPainting && (
        <div className="p-2 border-b border-gray-300">
          <div className="grid grid-cols-2">
            <p className="text-base">見送り絵:</p>
            <p className="text-base">{detail.farewellPainting}</p>
          </div>
        </div>
      )}
      {/* {detail.id && (
        <div className="p-2 border-b border-gray-300">
          <div className="grid grid-cols-2">
            <p className="text-base">No.:</p>
            <p className="text-base">{detail.id}</p>
          </div>
        </div>
      )} */}
      {/* {detail.width && (
        <div className="p-2 border-b border-gray-300">
          <div className="grid grid-cols-2">
            <p className="text-base">幅:</p>
            <p className="text-base">{detail.width}m</p>
          </div>
        </div>
      )} */}
      {/* {detail.frontType && (
        <div className="p-2 border-b border-gray-300">
          <div className="grid grid-cols-2">
            <p className="text-base">前ねぷた型:</p>
            <p className="text-base">{detail.frontType}</p>
          </div>
        </div>
      )} */}
      {/* {detail.frontHeight && (
        <div className="p-2 border-b border-gray-300">
          <div className="grid grid-cols-2">
            <p className="text-base">前ねぷた高さ:</p>
            <p className="text-base">{detail.frontHeight}m</p>
          </div>
        </div>
      )} */}
      {/* {detail.organizationInfo && (
        <div className="p-2 border-b border-gray-300">
          <div className="grid grid-cols-2">
            <p className="text-base">団体情報:</p>
            <p className="text-base">{detail.organizationInfo}</p>
          </div>
        </div>
      )} */}
      {/* {detail.neputaPictureInfo && (
        <div className="p-2 border-b border-gray-300">
          <div className="grid grid-cols-2">
            <p className="text-base">ねぷた絵情報:</p>
            <p className="text-base">{detail.neputaPictureInfo}</p>
          </div>
        </div>
      )} */}
    </>
  );

  const content = (
    <div style={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column' }}>
      <button
        type="button"
        onClick={onClose}
        onTouchEnd={handleTouchCloseClick}
        className="self-end hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
      >
         <AiOutlineClose size={24} />
      </button>
      <div>
        <h3 className="text-xl font-semibold my-4 ml-4">{detail.name}</h3>
        <div className="flex-1 overflow-auto">
          {renderDetails()}
        </div>
      </div>
    </div>
  );

  return isMobile ? (
    <div
      className="fixed bg-gray-100 z-1000 p-4 overflow-auto"
      style={{
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '95%',
        height: '500px',
        borderRadius: '20px',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.3)',
      }}
    >
      {content}
    </div>
  ) : (
    <Rnd
      className="fixed inset-0 flex items-center justify-center bg-gray-100 z-1000 rounded-3xl p-4 shadow-lg overflow-hidden"
      default={{
        x: (window.innerWidth - (window.innerWidth * 0.4)) / 2,
        y: (window.innerHeight - (window.innerHeight * 0.55)) / 2,
        width: window.innerWidth * 0.4,
        height: window.innerHeight * 0.55,
      }}
      minHeight={100}
      minWidth={100}
      bounds="parent"
      enableResizing={{
        top: true,
        right: true,
        bottom: true,
        left: true,
        topRight: true,
        bottomRight: true,
        bottomLeft: true,
        topLeft: true,
      }}
      onResizeStop={(e, direction, ref, delta, position) => {
        e.stopPropagation();
      }}
    >


      {content}
    </Rnd>
  );
};

export default BusMarkerDetails;
