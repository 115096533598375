import React from 'react';
import { useGetGroupName } from '../hooks/useGroupName';

const SidebarMenuAnnounce: React.FC = () => {
    const groupName = useGetGroupName();

    switch (groupName) {
        case 'neputa':
            return (
                <div>
                    <table className="min-w-full bg-white border">
                        <thead>
                            <tr>
                                <th className="py-2 px-4 border-b text-l">時間</th>
                                <th className="py-2 px-4 border-b text-l">イベント</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td className="py-2 px-4 border-b text-sm">19:00～</td>
                                <td className="py-2 px-4 border-b text-sm">駅前コース</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            );
        default:
            return null;
    }
};

export default SidebarMenuAnnounce;