import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import ContactFormModal from './contactFormModal';

const TopPage: React.FC = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isFirstVisitChecked, setIsFirstVisitChecked] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const handleFormSubmit = () => {
    closeModal();
    toast.success('送信が完了しました！');
    navigate('/hirosaki');
  };

  useEffect(() => {
    // 最初のアクセスかどうかを確認するためにlocalStorageを利用
    const firstVisit = localStorage.getItem('firstVisitToHirosaki');
    if (!firstVisit) {
      localStorage.setItem('firstVisitToHirosaki', 'true');
      setIsModalOpen(true);
    }
    setIsFirstVisitChecked(true);
  }, []);

  useEffect(() => {
    if (!isFirstVisitChecked) return;
    const params = new URLSearchParams(location.search);
    if (params.get('openModal') === 'true') {
      openModal();
    }
  }, [location, isFirstVisitChecked]);

  return (
    <div className="relative min-h-screen flex flex-col">
      <div className="fixed inset-0 -z-10">
        <img 
          src='/hirosaki-neputa-top.webp' 
          alt='Hirosaki Neputa' 
          className="w-full h-full object-cover"
        />
        <div className="absolute inset-0 bg-black bg-opacity-50 backdrop-blur-[2px]"></div>
      </div>
      <div className="relative p-4 sm:p-8 text-white text-sm sm:text-xl md:text-2xl font-bold sm:flex z-10">
        <p className='sm:mr-4'>弘前大学 丹波研究室</p>
        <p>弘前ねぷた位置情報配信システム</p>
      </div>
      <div className="flex-grow flex flex-col space-y-6 p-4 sm:max-w-2xl sm:mx-auto z-10">
        <div className="w-full p-6 rounded-xl shadow-lg border-2 border-white">
          <p className="text-white text-lg mb-4">
            弘前大学 丹波研究室では、弘前ねぷたの位置情報をリアルタイムで配信しています。
          </p>
          <div className="flex justify-center">
            <button 
              className="font-bold w-full sm:w-1/2 px-6 py-3 bg-blue-500 text-white rounded-lg hover:bg-blue-700 transition duration-300 focus:outline-none"
              onClick={() => navigate('/neputa')}
            >
              ねぷたの位置を見る
            </button>
          </div>
        </div>
        <div className="w-full p-6 rounded-xl shadow-lg border-2 border-white">
          <p className="text-white text-lg mb-4">
            アンケートを実施しております。ご意見・ご要望（感想）をお聞かせください。
          </p>
          <div className="flex justify-center">
            <button 
              className="font-bold w-full sm:w-1/2 px-6 py-3 bg-green-500 text-white rounded-lg hover:bg-green-700 transition duration-300 focus:outline-none"
              onClick={openModal}
            >
              アンケートに回答する
            </button>
          </div>
        </div>
        <div className="w-full p-6 rounded-xl shadow-lg border-2 border-white">
          <p className="text-white text-lg mb-4">
            弘前大学では地元企業と協力してねぷたが市民により親しまれるよう「ねぷた位置情報システム」を運用しています。
          </p>
          <div>
            <ul className="text-white grid grid-cols-1 sm:grid-cols-2 gap-2 list-none">
              <li className="bg-white flex justify-center items-center">
                <img src="https://neputa.koustate.com/hirosaki-shi.webp" alt="弘前市" className="max-h-full px-8" />
              </li>
              <li className="bg-white flex justify-center items-center">
                <img src="https://neputa.koustate.com/mi-motekku.webp" alt="ミーモテック" className="max-h-full" />
              </li>
              <li className="bg-white flex justify-center items-center">
                <img src="https://neputa.koustate.com/yamato.webp" alt="やまと印刷" className="max-h-full" />
              </li>
              <li className="bg-white flex justify-center items-center">
                <img src="https://neputa.koustate.com/nissann.webp" alt="日産サンティオ" className="max-h-full" />
              </li>
              <li className="bg-white flex justify-center items-center">
                <img src="https://neputa.koustate.com/konpumoto.webp" alt="コンプモト" className="max-h-full" />
              </li>
              <li className="bg-white flex justify-center items-center">
                <img src="https://neputa.koustate.com/touyou.webp" alt="東洋美装" className="max-h-full" />
              </li>
            </ul>
          </div>
        </div>
        <ContactFormModal isOpen={isModalOpen} onClose={closeModal} onSubmit={handleFormSubmit} />
      </div>
      <ToastContainer />

      <footer className="relative z-10 text-center py-4 bg-gray-800 text-white mt-4">
        <p className="text-sm">
          &copy; 2024 弘前大学 丹波研究室. All rights reserved.
        </p>
      </footer>
    </div>

  );  
}

export default TopPage;
