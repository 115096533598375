import axios from 'axios';
import { Bus, BusGroup } from './getBusLocationType';

// const API_BASE_URL = 'http://localhost:8001'; // backend APIのリクエスト先
// const API_BASE_URL = 'http://localhost:8080'; // mock APIのリクエスト先
const API_BASE_URL = 'https://services.loc.koustate.com'; // APIのリクエスト先

export const getSingleBusLocation = async (busUuid: string): Promise<Bus> => {
  try {
    const response = await axios.get<Bus>(`${API_BASE_URL}/device/${busUuid}`);
    return response.data;
  } catch (error) {
    throw error;
  }
}

export const getGroupBusLocations = async (busGroupName: string): Promise<BusGroup> => {
  try {
    const response = await axios.get<BusGroup>(`${API_BASE_URL}/devices/${busGroupName}`);
    return response.data;
  } catch (error) {
    throw error;
  }
}